<template>
<div>
<div class="Title">Kvkk Onay Metni</div>
<div class="TextArea">
<p><strong>KİŞİSEL VERİLERİN İŞLENMESİ VE G&Uuml;VENLİĞİ ONAY FORMU</strong></p>
<p>Bu dokumanı onaylayarak 6698 Sayılı Kişisel Verilerin Korunması Kanunu&rsquo;nun (&ldquo;Kanun&rdquo;) 5. Maddesi kapsamında Şirketimize iletmiş olduğunuz kişisel verilerinizin, hizmetlerimiz hakkında bilgi vermek ve sizlerle iletişime ge&ccedil;mek, istatistiki bilgi toplamak ve bu işlemlerin yapılması amacıyla verileri yurti&ccedil;i ve yurtdışında yerleşik &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarmak maksadıyla Şirketimiz tarafından işlenmesine a&ccedil;ık&ccedil;a rıza g&ouml;stermektesiniz. Kişisel verileriniz kanuni d&uuml;zenlemelere uygun olarak ticari team&uuml;llerde &ouml;ng&ouml;r&uuml;len s&uuml;relerde işlenecektir. Kanun kapsamında Veri Sorumlumuz; Kanun&rsquo;daki t&uuml;m sorumluluk ve y&uuml;k&uuml;ml&uuml;l&uuml;klerine uymayı kabul etmektedir.</p>
<p>Kişisel Verilerinizin, Veri Sorumlusu şahsın/Şirketimizin belirleyeceği yurti&ccedil;i veya yurtdışında bulunan 3. kişilere işbu verilerin saklanması, depolanması ve a&ccedil;ık rıza g&ouml;sterilen ama&ccedil;larla işlenmesi i&ccedil;in aktarılmasına a&ccedil;ık&ccedil;a onay vermektesiniz.</p>
<p>Kanuni haklarınız kapsamında; dilediğiniz zaman Veri Sorumlusu Şirketimize başvurarak tarafınıza ait kişisel verilerin işlenip işlenmediğini &ouml;ğrenme, işlenen kişisel verileri varsa bunlara ilişkin bilgi talep etme, kişisel verilerin işlenme amacını ve bu verilerin amaca uygun kullanılıp kullanılmadığını &ouml;ğrenme, kişisel verilerinin aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme, kişisel verilerindeki hataların d&uuml;zeltilmesini ve eğer aktarım yapılmışsa ilgili &uuml;&ccedil;&uuml;nc&uuml; kişiden bu d&uuml;zeltmenin istenmesini talep etme, kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde bu verilerin silinmesini, yok edilmesini ya da anonim hale getirilmesini isteme ve eğer aktarım yapılmışsa bu talebin aktarılan &uuml;&ccedil;&uuml;nc&uuml; kişiye iletilmesini isteme, işlenen verilerin neticesinde kişi ile ilintili olumsuz bir sonu&ccedil; &ccedil;ıkmasına itiraz etme, Kanun&rsquo;a aykırı veri işleme nedeniyle zararının ortaya &ccedil;ıkması halinde zararını yasalar &ccedil;er&ccedil;evesinde talep etme hakkınız mevcuttur. Bu hususta her t&uuml;r iletişiminiz veya şik&acirc;yet ve başvuru haklarınız i&ccedil;in gerekli bilgiler web sitemiz &uuml;zerinde bilgilerinize sunulmuştur:</p>
<p>Şirketimiz tarafından 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ilgili mevzuat h&uuml;k&uuml;mleri kapsamında yapılmış olan işbu bilgilendirme ve aydınlatma sonrasında kişisel verileriniz aşağıdaki a&ccedil;ık muvafakatiniz ile toplanacaktır:</p>
<p><strong>Orhan Giyim | Tusse | Orhan ÖZKARA&nbsp;</strong>tarafından a&ccedil;ıklanan 6698 sayılı Kişisel Verilerin Korunması Kanunu&rsquo;na ilişkin aydınlatma metninin tamamını okudum, anladım ve&nbsp;<strong>Orhan Giyim | Tusse | Orhan ÖZKARA&rsquo;nin&nbsp;</strong>Kişisel Verilerimi yukarıda belirtilen ama&ccedil;lar &ccedil;er&ccedil;evesinde işlemesi konusunda bilgilendirildim. Bu kapsamda Kişisel Verilerimin Aydınlatma Metni&rsquo;nde belirtilen hususlar dahilinde paylaşılmasına konu hakkında teredd&uuml;de yer vermeyecek şekilde aydınlatılmış ve bilgi sahibi olarak, a&ccedil;ık rızamla onay veriyorum.</p>
</div>
</div>
    
</template>
<script>
export default {
    
}
</script>